import {ApiContactFrom, ApiUserMe} from "../interface";
import {wpClient} from "./client";

export class ContactForm7Api {
  static async feedback(
    token: string | null,
    formId: string,
    params: Record<string, string>
  ) {
    const user: ApiUserMe = await wpClient.get("wp/v2/users/me", {
      params: {
        JWT: token,
        acf_format: "standard",
      },
    });

    const data: ApiContactFrom = await wpClient.post(
      `contact-form-7/v1/contact-forms/${formId}/feedback`,
      {
        ...user.acf,
        ...user.extra,
        id: user.id,
        ...params,
        _wpcf7_unit_tag: formId,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return data;
  }
}
