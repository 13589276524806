import {useParams} from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {Container} from "../components/Container";
import {getResponsiveValue} from "../utilities/hooks";
import {useMemo} from "react";
import {ArrowRight} from "../components/Icons";
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../constants/queryKeys";
import {OpportunityApi} from "../utilities/api/OpportunityApi";
import {Categories} from "../utilities/interface";
import {Image} from "../components/Image";
import {Download} from "../components/Download";
import {LoadingPage} from "./LoadingPage";
import {htmlParse} from "../utilities/htmlParse";

interface OpportunityPageProps {
  map_slug: string;
  opportunity_list_slug: string;
}

export const OpportunityPage = ({
  map_slug,
  opportunity_list_slug,
}: OpportunityPageProps) => {
  const { id } = useParams<{ id: string }>();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { data } = useQuery({
    queryFn: () => OpportunityApi.list(language),
    queryKey: [QueryKeys.GET_OPPORTUNITY, language],
  });

  const opportunity = data?.find(({ slug }) => decodeURI(slug) === id);

  const categories = useMemo(
    () =>
      data?.reduce((acc, opportunity) => {
        if (acc[opportunity.category] == null) {
          acc[opportunity.category] = [];
        }

        acc[opportunity.category].push(opportunity);

        return acc;
      }, {} as Categories),
    [data]
  );

  return opportunity == null ? (
    <LoadingPage />
  ) : (
    <>
      <Box
        sx={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/img/star.png)`,
          backgroundPosition: "right top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          bgcolor: "#EAEAEA",
          p: 2,
          position: "relative",
          py: 14,
        }}
      >
        <Container>
          <Grid2 spacing={getResponsiveValue(5, 20)} container>
            <Grid2 size={getResponsiveValue(12, 8)}>
              <Stack spacing={4}>
                {opportunity.image != null && (
                  <Image
                    src={opportunity.image}
                    sx={{
                      height: 525,
                      objectFit: "cover",
                      width: "100%",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: 35,
                    fontWeight: 700,
                  }}
                >
                  {opportunity.name}
                </Typography>

                <Typography>{htmlParse(opportunity.content)}</Typography>

                <Button
                  color="success"
                  href={`/${map_slug}/${opportunity.slug}`}
                  size="large"
                  sx={{
                    textTransform: "capitalize",
                    // width: 260,
                  }}
                  variant="contained"
                >
                  {t("VIEW_ON_MAP")}
                </Button>
              </Stack>
            </Grid2>
            <Grid2 size={getResponsiveValue(12, 4)}>
              <Box
                sx={{
                  bgcolor: "info.main",
                  p: 4,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 700,
                  }}
                >
                  {t("SECTORS")}
                </Typography>
                <Divider sx={{ my: 3 }} />
                <List>
                  {Object.keys(categories ?? []).map((category) => (
                    <ListItem key={category} disablePadding>
                      <ListItemButton
                        component="a"
                        href={`/${opportunity_list_slug}/${category}`}
                      >
                        <ListItemIcon>
                          <ArrowRight color="success" />
                        </ListItemIcon>
                        <ListItemText primary={category} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      {opportunity.pdf == null ? null : (
        <Download file={opportunity.pdf.url} image={opportunity.pdf.image} />
      )}
    </>
  );
};

export default OpportunityPage;
